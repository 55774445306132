<template>
  <div class="nothing-found">
    <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.07697 83.8638C15.7571 79.6389 25.0282 79.4794 32.849 83.4378L33.8133 83.9258C34.3061 84.1752 34.5033 84.7769 34.2539 85.2697C34.0045 85.7624 33.4029 85.9597 32.9101 85.7103L31.9459 85.2222C24.7145 81.5622 16.1422 81.7097 9.04096 85.6161C2.76594 89.0681 2.42998 97.9613 8.42667 101.877L27.0955 114.067C27.5579 114.369 27.688 114.988 27.3861 115.451C27.0841 115.913 26.4645 116.043 26.002 115.741L7.33322 103.551C0.0727105 98.8107 0.479489 88.0433 8.07697 83.8638Z" fill="#E9A35D"/>
      <path d="M23.5788 110.729C24.1151 110.42 24.6009 110.391 24.9518 110.595L26.4375 111.458C26.0867 111.254 25.6008 111.283 25.0645 111.592C23.9988 112.207 23.1378 113.698 23.1413 114.921C23.143 115.529 23.3577 115.957 23.7041 116.158L22.2184 115.296C21.872 115.094 21.6573 114.667 21.6555 114.059C21.6521 112.835 22.5131 111.344 23.5788 110.729Z" fill="#D06E0B"/>
      <path d="M23.7045 116.159C22.9522 115.722 22.9542 114.316 23.709 113.018C24.4638 111.72 25.6855 111.021 26.4378 111.458C27.1901 111.895 27.1881 113.302 26.4333 114.6C25.6785 115.898 24.4568 116.596 23.7045 116.159Z" fill="#E9A35D"/>
      <path d="M25.2135 112.194C25.5748 111.993 25.9036 111.96 26.1557 112.07L43.6387 119.657C43.1145 119.43 42.4307 119.497 41.6794 119.915C40 120.851 38.6193 123.186 38.5954 125.131C38.5844 126.034 38.8671 126.694 39.3411 127.049C34.257 123.24 29.1731 119.432 24.089 115.624C23.8611 115.453 23.7252 115.136 23.7305 114.702C23.7419 113.767 24.4059 112.644 25.2135 112.194Z" fill="#E9A35D"/>
      <path d="M39.4819 127.143C38.2962 126.454 38.2994 124.238 39.489 122.192C40.6786 120.146 42.6041 119.046 43.7898 119.735C44.9755 120.423 44.9723 122.64 43.7827 124.686C42.5931 126.731 40.6676 127.832 39.4819 127.143Z" fill="#D06E0B"/>
      <path d="M67.3963 120.934L116.303 93.1247L67.3963 64.998L19.125 92.9658L67.3963 120.934Z" fill="#495469"/>
      <path d="M67.3965 120.934V124.123L116.303 96.3145V93.125L67.3965 120.934Z" fill="#2E3648"/>
      <path d="M67.3963 120.933V124.122L19.125 96.1543V92.9648L67.3963 120.933Z" fill="#6F7A90"/>
      <ellipse cx="67.5" cy="60.75" rx="31.5" ry="18" fill="#495469"/>
      <path d="M90.3209 30.2623C89.4702 27.4507 88.2031 24.7282 86.5194 22.1007C84.8358 19.4752 82.7909 17.0514 80.3868 14.8374C77.9808 12.6234 75.2451 10.7319 72.1719 9.16288C69.3257 7.70863 66.7025 6.91721 64.3024 6.77475C62.0068 6.64416 60.0074 7.02999 58.3001 7.94013L48.7646 13.0191C50.472 12.109 52.4734 11.7231 54.767 11.8537C57.1671 11.9962 59.7903 12.7876 62.6365 14.2418C65.7097 15.8108 68.4454 17.7043 70.8514 19.9164C73.2555 22.1284 75.3004 24.5541 76.984 27.1797C78.6677 29.8092 79.9329 32.5317 80.7855 35.3413C81.6362 38.1607 82.0428 40.9505 82.0073 43.7205C81.9836 45.5427 81.8198 47.1276 81.5178 48.467C81.2138 49.8105 80.7244 50.9897 80.0552 52.0067C79.3822 53.0237 78.4959 53.9477 77.3985 54.7826C76.5754 55.4059 75.6201 56.0311 74.5385 56.6583C74.276 56.8106 74.0056 56.961 73.7292 57.1134L72.8686 57.5724C71.7988 58.1422 70.9442 58.6547 70.2988 59.1117C69.5467 59.6459 68.9822 60.1742 68.6013 60.6946C68.2223 61.2149 67.9736 61.7333 67.8532 62.2477C67.7289 62.7661 67.6677 63.328 67.6598 63.9355L67.6341 65.9615L77.1715 60.8806L77.1972 58.8545C77.2051 58.2471 77.2682 57.6852 77.3906 57.1668C77.511 56.6524 77.7597 56.132 78.1387 55.6136C78.5196 55.0933 79.0841 54.565 79.8361 54.0308C80.3197 53.6865 80.9217 53.3125 81.6441 52.9069C81.7349 52.8555 81.8297 52.8041 81.9244 52.7506L82.9508 52.2026C84.5061 51.3656 85.8365 50.5327 86.9339 49.6997C88.0313 48.8647 88.9176 47.9407 89.5906 46.9238C90.2617 45.9068 90.7493 44.7276 91.0532 43.3841C91.3552 42.0446 91.519 40.4598 91.5427 38.6375C91.5783 35.8695 91.1717 33.0798 90.3209 30.2623Z" fill="#E14761"/>
      <path d="M62.636 14.2423C65.7092 15.8113 68.4449 17.7048 70.8509 19.9168C73.255 22.1288 75.2999 24.5546 76.9835 27.1801C78.6671 29.8096 79.9324 32.5321 80.785 35.3417C81.6357 38.1611 82.0423 40.9509 82.0068 43.7209C81.9831 45.5432 81.8193 47.128 81.5173 48.4675C81.2133 49.8109 80.7238 50.9902 80.0547 52.0071C79.3817 53.0241 78.4954 53.9481 77.398 54.7831C76.2986 55.616 74.9702 56.449 73.4149 57.286C72.0885 57.9705 71.0503 58.578 70.2983 59.1122C69.5462 59.6464 68.9817 60.1747 68.6008 60.695C68.2218 61.2154 67.9731 61.7338 67.8527 62.2482C67.7284 62.7666 67.6672 63.3285 67.6593 63.9359L67.6336 65.962L56.3712 60.2083L56.4244 56.1562C56.458 53.588 57.0541 51.5639 58.2226 50.084C59.3871 48.606 61.2662 47.2388 63.8597 45.9785C65.9342 44.9298 67.5646 43.9267 68.7528 42.9671C69.939 42.0095 70.545 40.484 70.5726 38.3867C70.6062 35.8205 69.9351 33.3869 68.5633 31.0878C67.1895 28.7887 65.1664 26.9565 62.4939 25.5913C60.0464 24.3409 58.0588 24.1252 56.531 24.9424C55.0053 25.7615 54.2217 27.6233 54.1822 30.5278L42.75 24.6891C42.7855 21.9171 43.3185 19.5547 44.3448 17.5979C45.3692 15.6411 46.7647 14.1612 48.5253 13.1521C50.286 12.147 52.3663 11.7176 54.7704 11.8522C57.1666 11.9966 59.7898 12.788 62.636 14.2423Z" fill="#F196A5"/>
      <circle cx="67.5731" cy="79.5574" r="9.07307" fill="#E14761"/>
      <path d="M67.3408 88.6287C62.4372 88.5055 58.5 84.4918 58.5 79.5585C58.5 75.8703 60.7006 72.6961 63.8605 71.2773C68.7641 71.4005 72.7013 75.4142 72.7013 80.3475C72.7013 84.0357 70.5007 87.2099 67.3408 88.6287Z" fill="#F196A5"/>
      <path d="M67.5 78.75C84.897 78.75 99 70.6911 99 60.75V88.875C99 98.8161 84.897 106.875 67.5 106.875C50.103 106.875 36 98.8161 36 88.875V60.75C36 70.6911 50.103 78.75 67.5 78.75Z" fill="#6F7A90"/>
    </svg>

    <span v-if="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'NothingFound',

  props: {
    text: {
      type: String,
    },
  },
}
</script>

<style lang="scss">
.nothing-found {
  height: 330px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    display: block;

    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--main-color-dark-trans-middle);

    margin-top: 16px;
  }
}

</style>
